<template>
  <vx-card :title="title">
    <div>
      <vs-tabs>
        <vs-tab label="Data">
          <div class="tab-text">
            <!-- <customer-category
              @data="setCustomerCategoryId"
            ></customer-category> -->
            <territory @data="setTerritoryId"></territory>
            <div class="vx-row mb-6" style="width:70%">
              <div class="vx-col sm:w-1/4 w-full flex items-center">
                <span>Sales</span>
              </div>
              <div class="vx-col sm:w-3/4 w-full">
                <multiselect
                  v-model="selectedSales"
                  :options="optionsSales"
                  placeholder="Type to search"
                  track-by="salesman_name"
                  label="salesman_name"
                  :max-height="125"
                  :allow-empty="false"
                  :limit="5"
                  @search-change="handlerSearchSales"
                  :searchable="true"
                  :internal-search="false"
                  :multiple="false"
                  :group-select="false"
                  :disabled="false"
                >
                  <span slot="noResult">Oops! No data found</span>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.salesman_code }} {{ props.option.salesman_name }}</span>
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.salesman_code }} {{ props.option.salesman_name }}</span>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
              <div class="vx-col sm:w-1/4 w-full flex items-center">
                <span>Customer</span>
              </div>
              <div class="vx-col sm:w-3/4 w-full">
                <multiselect
                  v-model="selectedCustomer"
                  :options="optionsCustomer"
                  :multiple="false"
                  :allow-empty="false"
                  :group-select="false"
                  :searchable="true"
                  :loading="isLoadingCus"
                  :internal-search="false"
                  :max-height="200"
                  :limit="5"
                  :disabled="false"
                  placeholder="Type to search"
                  track-by="customer_name"
                  label="customer_name"
                  @search-change="handlerSearchCustomer"
                >
                  <span slot="noResult">Oops! No data found</span>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.customer_code }} {{ props.option.customer_name }}</span>
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{ props.option.customer_code }} {{ props.option.customer_name }}</span>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
              <div class="vx-col sm:w-1/4 w-full">
                <div class="d-flex vx-row">
                  <span class="vx-col sm:w-1/2 w-full">Created Date</span>
                  <span class="vx-col sm:w-1/2 w-full">
                    <vs-button
                      style="float: right;"
                      color="danger"
                      type="line"
                      icon-pack="feather"
                      icon="icon-refresh-cw"
                      @click="resetDate()"
                    ></vs-button>
                  </span>
                </div>
                
              </div>
              <div class="vx-col sm:w-3/4 w-full d-flex">
                <date-range-picker
                  style="min-height: 40px"
                  class="w-full"
                  ref="picker"
                  opens="center"
                  :locale-data="{ firstDay: 1, format: 'dd Mon yyyy' }"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  :clear="true"
                  v-model="validity"
                  :linkedCalendars="true"
                >
                  <template v-slot:input="picker" style="min-width: 350px">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
              </div>
            </div>
            <div class="vx-row mb-12">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <data-table
                  :territorryId="territorryId"
                  :territoryCode="territoryCode"
                  :dateFrom="validity.startDate"
                  :dateTo="validity.endDate"
                  :salesPersonalID="selectedSales.sales_personal_id"
                  :customerID="selectedCustomer.customer_id"
                ></data-table>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <div class="vx-row mb-12">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <export></export>
              </div>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable";
import Export from "./Export";
// import CustomerCategory from "./CustomerCategory";
import Territory from "./Territory";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  components: {
    DataTable,
    // CustomerCategory,
    Territory,
    Export,
    DateRangePicker,
  },
  data() {
    return {
      title: "Customer Survey Report",
      // customerCategoryId: null,
      // customerCategoryName: null,
      territorryId: null,
      territoryCode: null,
      validity: {
        startDate: null,
        endDate: null,
      },
      selectedSales: {
        sales_personal_id: 0,
        salesman_code: "",
        salesman_name: "All"
      },
      optionsSales: [],
      selectedCustomer: {
        customer_id: 0,
        customer_code: "",
        customer_name: "All"
      },
      optionsCustomer: [],
      searchCust: "",
      searchSales: "",
      isLoadingCus:false,
    };
  },
  methods: {
    // setCustomerCategoryId(id, name) {
    //   this.customerCategoryId = id;
    //   this.customerCategoryName = name
    // },
    setTerritoryId(id, code) {
      this.territorryId = id;
      this.territoryCode = code

      console.log("get data sales and customer")
      this.getDataSales(id)
      this.getDataCustomer(id)
    },
    getDataSales(territoryID) {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/customer-survey/report-sales", {
          params: {
            territory_id: territoryID,
            length: 50,
            search: this.searchSales,
          }
        })
        .then(resp => {
          if (resp.code == 200) {
            var optionsSales = [];
            optionsSales.push({
              sales_personal_id: 0,
              salesman_code: "",
              salesman_name: "All"
            });
            // this.selectedSales = optionsSales[0];
            resp.data.records.forEach(function(item) {
              optionsSales.push(item);
            }, optionsSales);
            this.optionsSales = optionsSales;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    getDataCustomer(territoryID) {
      this.isLoadingCus = true
      this.$http
        .get("/api/sfa/v1/customer-survey/report-customer", {
          params: {
            territory_id: territoryID,
            length: 50,
            search: this.searchCust,
          }
        })
        .then(resp => {
          this.isLoadingCus = false
          if (resp.code == 200) {
            var optionsCustomer = [];
            optionsCustomer.push({
              customer_id: 0,
              customer_code: "",
              customer_name: "All"
            });
            resp.data.records.forEach(function(item) {
              optionsCustomer.push(item);
            }, optionsCustomer);
            this.optionsCustomer = optionsCustomer;
          } else {
            console.log(resp.data);
          }
        });
    },
    handlerSearchCustomer(search){
      console.log("search customer")
      this.searchCust = search
      this.getDataCustomer(this.territorryId)
    },
    handlerSearchSales(search){
      console.log("search sales")
      this.searchSales = search
      this.getDataSales(this.territorryId)
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
    resetDate(){
      this.validity.startDate = null
      this.validity.endDate = null
    },
  },
  computed: {},
};
</script>
  
<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.ps {
  height: 800px;
}
</style>