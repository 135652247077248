<template>
  <div>
    <div class="vx-row ml-1 mt-3">
      <vs-button
        color="success"
        icon-pack="feather"
        icon="icon-filter"
        @click="getData()"
      >Get Data</vs-button>
      <vs-button
        class="ml-5"
        color="success"
        icon-pack="feather"
        icon="icon-download"
        @click="doExport()"
      >Export</vs-button>
    </div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      :buttonSearch="true"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th sort-key="company_code">Company</vs-th>
        <vs-th sort-key="territory_code">TerritoryId</vs-th>
        <vs-th sort-key="territory_name">Territory Name</vs-th>
        <vs-th sort-key="salesman_code">Salesman Code</vs-th>
        <vs-th sort-key="salesman_name">Salesman Name</vs-th>
        <vs-th sort-key="salesman_type">Salesman Type</vs-th>
        <vs-th sort-key="salesman_subtype">Salesman SubType</vs-th>
        <vs-th sort-key="customer_sold_to_code">Customer Sold To Code</vs-th>
        <vs-th sort-key="customer_sold_to_name">Customer Sold to Name</vs-th>
        <vs-th sort-key="customer_ship_to_code">Customer Ship To Code</vs-th>
        <vs-th sort-key="customer_ship_to_name">Customer Ship To Name</vs-th>
        <vs-th sort-key="survey_id">Survey ID</vs-th>
        <vs-th sort-key="survey_description">Survey Desc</vs-th>
        <vs-th sort-key="no">No</vs-th>
        <vs-th sort-key="question">Question</vs-th>
        <vs-th sort-key="item_answer">ItemAnswer</vs-th>
        <vs-th sort-key="answer">Answer</vs-th>
        <vs-th sort-key="created">Created</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.company_code">{{ tr.company_code }}</vs-td>
          <vs-td :data="tr.territory_code">{{ tr.territory_code }}</vs-td>
          <vs-td :data="tr.territory_name">{{ tr.territory_name }}</vs-td>
          <vs-td :data="tr.salesman_code">{{ tr.salesman_code }}</vs-td>
          <vs-td :data="tr.salesman_name">{{ tr.salesman_name }}</vs-td>
          <vs-td :data="tr.salesman_type">{{ tr.salesman_type }}</vs-td>
          <vs-td :data="tr.salesman_subtype">{{ tr.salesman_subtype }}</vs-td>
          <vs-td :data="tr.customer_sold_to_code">{{ tr.customer_sold_to_code }}</vs-td>
          <vs-td :data="tr.customer_sold_to_name">{{ tr.customer_sold_to_name }}</vs-td>
          <vs-td :data="tr.customer_ship_to_code">{{ tr.customer_ship_to_code }}</vs-td>
          <vs-td :data="tr.customer_ship_to_name">{{ tr.customer_ship_to_name }}</vs-td>
          <vs-td :data="tr.survey_id">{{ tr.survey_id }}</vs-td>
          <vs-td :data="tr.survey_description">{{ tr.survey_description }}</vs-td>
          <vs-td :data="tr.no">{{ tr.no }}</vs-td>
          <vs-td :data="tr.question">{{ tr.question }}</vs-td>
          <vs-td :data="tr.item_answer">{{ tr.item_answer }}</vs-td>
          <vs-td v-if="tr.type_question == 'Image'" :data="tr.answer"><img style="max-height: 100px; max-width: auto" :src="tr.answer" /></vs-td>
          <vs-td v-else :data="tr.answer">{{ tr.answer }}</vs-td>
          <vs-td :data="tr.created">{{ tr.created }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    // customerCategoryId: {
    //   type: Number,
    // },
    // customerCategoryName: {
    //   type: String,
    // },
    territorryId: {
      type: Number,
    },
    territoryCode: {
      type: String,
    },
    dateFrom: {
      type: Date,
    },
    dateTo: {
      type: Date,
    },
    salesPersonalID: {
      type: Number,
    },
    customerID: {
      type: Number,
    },
  },
  data() {
    return {
      baseUrl: "/api/sfa/v1/customer-survey",
      baseUrlOms: "/api/oms/v1/log",
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    testEnter(){
      console.log("test enter")
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      if (searching.length >= 3) {
        this.getData();
      } else if(searching.length == 0) {
        this.getData();
      }
    },
    handleChangePage(page) {
      this.table.page = page;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/report", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            // customer_category_id: this.customerCategoryId,
            territory_id: this.territorryId,
            date_from: this.dateFrom == null ? "0001-01-01" : moment(this.dateFrom).format("YYYY-MM-DD"),
            date_to: this.dateTo == null ? "0001-01-01" : moment(this.dateTo).format("YYYY-MM-DD"),
            sales_personal_id: this.salesPersonalID,
            customer_id: this.customerID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    doExport() {
      if ((this.table.data).length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Validation",
          text: "Get Data First",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.loading();
        this.$http
          .get(this.baseUrlOms + "/export/customer-survey", {
            params: {
              title: "Report-Customer-Survey-" + (this.territoryCode == null ? "All" : this.territoryCode),
              territory_id: this.territorryId,
              date_from: this.dateFrom == null ? "0001-01-01" : moment(this.dateFrom).format("YYYY-MM-DD"),
              date_to: this.dateTo == null ? "0001-01-01" : moment(this.dateTo).format("YYYY-MM-DD"),
              sales_personal_id: this.salesPersonalID,
              customer_id: this.customerID,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.$vs.loading.close();
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Form Validation",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.$vs.loading.close();
            }
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  watch: {
    // customerCategoryId() {
    //   this.getData();
    // },
    // territorryId() {
    //   this.getData();
    // },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>